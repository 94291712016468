import {
    DESTROY_GAME,
    FETCH_GAME,
    FETCH_GAME_DATA,
    FETCH_GAME_DATA_ERROR,
    FETCH_GAMES,
    FETCH_STREAM,
    FETCH_TABLE
} from '../actions/types';

const initialState = {
  items: [],
  itemsLoaded:false,
  item: {},
  ids:[],
  itemData: {},
  itemLoaded: false,
  itemDataLoaded: false,
  itemStatus: 200,
  stream: {},
  table:[],
  tableLoaded:false,
};

export default function reducer(state = initialState, action) {
  //console.log(action.type);
  switch (action.type) {
    case FETCH_TABLE:
      return {
        ...state,
        table: action.payload,
        tableLoaded:true
    };
    case FETCH_GAMES:
      return {
        ...state,
        items: action.payload,
        itemsLoaded:true
      };
    case FETCH_GAME:
      const t = state.items[0]?.games?.find(g => g.id === action.payload) ;
      return {
        ...state,
        item: t !== undefined ? t : {},
        ids:t !== undefined ? [t.teams.home.id,t.teams.away.id] : [],
        itemLoaded: true,
        itemStatus: t !== undefined ? 200 : 300
      };
      case FETCH_GAME_DATA:
        return {
          ...state,
          itemData: action.payload,
          ids:[action.payload.header?.competitions[0]?.competitors[1]?.id,action.payload.header?.competitions[0]?.competitors[0]?.id],
          itemDataLoaded: true
        };
    case FETCH_GAME_DATA_ERROR:
      return {
        ...state,
        itemDataLoaded: false,
        itemStatus: 404
      };
    case DESTROY_GAME:
        return {
          ...state,
          item: {},
          ids:[],
          itemLoaded: false,
          itemDataLoaded: false,
          itemStatus: 200
      };
    case FETCH_STREAM:
      return {
        ...state,
        stream: {
          id : action.meta,
          data : action.payload?.filter(row => row.username !== "")
        }
      };
    default:
      return state;
  }
}
