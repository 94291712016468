import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Grow, Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Skeleton from '@material-ui/lab/Skeleton';
import {Helmet} from 'react-helmet';

const { DateTime } = require("luxon");
const useStyles = makeStyles((theme) => ({
  small: {
     width: theme.spacing(4),
     height: theme.spacing(4),
   },
   skeleton:{
     margin:'auto',
   },
   skeletonListItem: {
    '&:not(:last-child):after':{
       background: 'rgba(255, 255, 255, 0.13) !important'
    },
   },
   ListItem: {
    flexDirection: 'column',
    paddingBottom:theme.spacing(1),
    '&:not(:last-child):after':{
       background: 'rgba(255, 255, 255, 0.08)',
       width: '100%',
       height: '1px',
       content: '""',
       bottom: '0',
       zIndex: '0',
       overflow: 'hidden',
       position: 'absolute',
    },
   },
   card:{
      marginBottom:theme.spacing(4),
      borderRadius: '15px !important',
   }
}));
function OutlinedCard(props) {
  const { to } = props;
  const dtG = (ev) => new Date(new Date(ev).getTime()-720*60000).toISOString().split('T')[0];
  const dates = props.data.games.map(ev => dtG(ev.date)).filter((item, pos) => props.data.games.map(ev => dtG(ev.date)).indexOf(item) === pos);
  const classes = useStyles();
  const CustomLink = React.forwardRef((props, ref) => ( <Link to={to} ref={ref} {...props} />));
  const logo = (src) => src.replace('a.espncdn.com','a3.espncdn.com/combiner/i?img=')+'?w=32&h=32';
  const Score = (props) => {
    let dt = {a:'',b:`${props.awayScore} - ${props.homeScore}`}
    switch (props.status) {
      case 'pre':
        dt = {a:'vs',b:DateTime.fromISO(props.date).toLocaleString({hour: "2-digit",minute: "2-digit",hour12: false})}
        break;
      case 'in':
        dt.a = 'Live'
        break;
      case 'post':
        dt = {a:props.summary,b:props.summary !== 'Postponed' ? `${props.awayScore} - ${props.homeScore}` : ''}
        break;
      default: dt.b = ''
    }
    return (
      <Grid item xs={2}>
        <Typography variant="caption" display="block" align="center" noWrap>{dt.a}</Typography>
        <Typography variant="caption" display="block" align="center">{dt.b}</Typography>
      </Grid>
    )
  }
  return (
    <>
    {dates.map((dateGame) => (<Card className={classes.card} key={dateGame}>
        <CardHeader
          avatar={<Avatar alt={props.data.league.name+" logo"} variant="square" src={props.data.league.logoDark+'?w=40&h=40'} />}
          title={props.data.league.shortName}
          subheader={new Date(dateGame).toLocaleString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' })} />
        <List component="div">
            {props.data.games.map((game) => dtG(game.date) === dateGame && (
              <ListItem button component={CustomLink} key={game.id} to={`/game/${window.btoa(game.id)}/${game.slug}`} className={classes.ListItem}>
                <Grid item container justify="center" alignItems="center">
                  <Grid item xs={5}>
                    <Grid container direction="row-reverse" justify="center" alignItems="center" wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar variant="square" src={logo(game.teams.away.logo)} alt={game.teams.away.displayName+" logo"} className={classes.small}></Avatar>
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Typography variant="body1" align="right" noWrap>{game.teams.away.name}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Score status={game.status} date={game.date} summary={game.summary} homeScore={game.teams.home.score} awayScore={game.teams.away.score} />
                  <Grid item xs={5}>
                    <Grid container wrap="nowrap" justify="center" alignItems="center" spacing={2}>
                      <Grid item>
                        <Avatar variant="square" src={logo(game.teams.home.logo)} alt={game.teams.home.displayName+" logo"} className={classes.small}></Avatar>
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Typography variant="body1" noWrap>{game.teams.home.name}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
        </List>
      </Card>
    ))}
    </>
  );
}

export function OutlinedCardSkeleton() {
  const classes = useStyles();
  return (
    <Box my={4}><Card className={classes.card}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circle" width={40} height={40}/>}
        title={<Skeleton animation="wave" height={10} width="10%" style={{ marginBottom: 5 }} />}
        subheader={<Skeleton animation="wave" height={8} width="25%" />} />
      <List component="div">
      {[0,1,2,3,4].map((game) => (
        <ListItem button key={game} className={classes.ListItem+' '+classes.skeletonListItem}>
          <Grid item container justify="center" alignItems="center">
            <Grid item xs={5}>
              <Grid container direction="row-reverse" justify="center" alignItems="center" wrap="nowrap" spacing={2}>
                <Grid item>
                  <Skeleton animation="wave" variant="circle" className={classes.small} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="body1" align="center" noWrap>
                    <Skeleton animation="wave" height={10} width="70%" className={classes.skeleton} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="center" noWrap>
                <Skeleton animation="wave" height={8} width="20%" className={classes.skeleton} />
                </Typography>
              <Typography variant="body2" align="center" noWrap>
                <Skeleton animation="wave" height={10} width="50%" className={classes.skeleton} />
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid container wrap="nowrap" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                  <Skeleton animation="wave" variant="circle" className={classes.small} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="body1" align="center" noWrap>
                    <Skeleton animation="wave" height={10} width="70%" className={classes.skeleton} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      ))}
      </List>
    </Card></Box>
  );
}
//MLB Streams free to Watch HD on MLB Streams. Its the best alternative for Reddit MLB Streams. MLB Streams is the official backup to the reddit MLB Streams
//
function Home(props) {
  return (<>
    <Helmet>
     <title>MLB Streams - Reddit MLB Streams</title>
     <link rel="canonical" href="https://mlbstreams.fun/" />
     <meta name="robots" content="follow, index" />
        <meta name="description" content="MLB Streams - Streams links for all MLB matches. Watch the latest MLB streams games for free! Reddit MLB Streams offers the latest MLB Streams links and MLB stats" />
     <meta property="og:locale" content="en_US"/>
     <meta property="og:type" content="website"/>
     <meta property="og:title" content="MLBStreams"/>
     <meta property="og:description" content="MLB Streams - Streams links for all MLB matches. Watch the latest MLB streams games for free! Reddit MLB Streams offers the latest MLB Streams links and MLB stats"/>
     <meta property="og:url" content={`https://mlbstreams.fun/`}/>
     <meta property="og:site_name" content="MLBStreams"/>
     <meta name="twitter:card" content="summary_large_image"/>
     <meta name="twitter:title" content={`MLBStreams`}/>
     <meta name="twitter:description" content="Watch MLB Streams for free on MLBStreams. Its the best alternative for Reddit MLBStreams. MLB Streams is the official backup to the reddit MLBStreams"/>
     <meta name="twitter:site" content="@mlbStreams"/>
     </Helmet>
    {props.gamesLoaded ? (
      <Box my={4}>
          {props.games.map((l) => (
            <OutlinedCard key={l.league.id} data={l} />
          ))}
      </Box>
    ) : <OutlinedCardSkeleton/>}</>);
}
Home.propTypes = {
  games: PropTypes.array.isRequired,
  gamesLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  games: state.games.items,
  gamesLoaded: state.games.itemsLoaded
});

export default connect(mapStateToProps)(Home);
