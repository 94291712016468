import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '40px 0px'
  },
}));

export default function MainAppBar() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Typography variant="body1" align="center" className={classes.title}>
        MLB Streams - Streams links for all MLB matches. Watch the latest MLB streams games for free! Reddit MLB Streams
        offers the latest MLB Streams links and MLB stats. Visit Mlbstreams.fun sport pages to get live
        stream links for your favorite team ...
        </Typography>
      </Container>
    </footer>
  );
}
