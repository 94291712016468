import React, {lazy, Suspense, useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchGames, fetchTable} from './actions/gameActions';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import {Route, Switch, useLocation} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ReactGA from "react-ga";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import AppBar from './components/AppBar';
import Footer from './components/Footer';
import Skeleton from '@material-ui/lab/Skeleton';
import {Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';

import OutlinedCardSkeleton from './pages/Home';
import AdUI from "./components/ad/AdUI";

const Home = lazy(() => import('./pages/Home'));
const Game = lazy(() => import('./pages/Game'));
const About = lazy(() => import('./pages/About'));
const Dcma = lazy(() => import('./pages/Dcma'));
const Contact = lazy(() => import('./pages/Contact'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Table = lazy(() => import('./components/Table'));

function App(props) {
  let location = useLocation();
  useEffect(() => { ReactGA.initialize('UA-146349963-5');},[]);
  useEffect(() => { ReactGA.pageview(location.pathname);}, [location]);
  useEffect(() => {
    !props.gamesLoaded && props.fetchGames();
  },[props.gamesLoaded,props.fetchGames]);
  useEffect(() => {
    !props.tableLoaded && props.fetchTable();
  },[props.tableLoaded,props.fetchTable]);
  const Loader = () => <Box my={20} display="flex" alignItems="center" justifyContent="center"><CircularProgress disableShrink color="inherit" /></Box>
  return (
    <>
      <AppBar/>
      <Container maxWidth="lg">
        <AdUI format="auto"/>
        <Grid container spacing={2}>
          <Hidden only={['xs', 'sm']}>
            <Grid item md={3} /*style={{maxWidth:'240px'}}*/>
              <AdUI format="horizontal"/>
              <AdUI format="rectangle"/>
              <Suspense fallback={
                <Box my={4} style={{position: 'sticky',top: '0'}}>
                  <List style={{paddingTop:'0'}}>
                  {[0,1,2,3,4].map(x =>
                    <ListItem key={x}>
                      <ListItemIcon><Skeleton animation="wave" variant="circle" width={24} height={24}/></ListItemIcon>
                      <ListItemText><Skeleton animation="wave" height={10} width="70%" /></ListItemText>
                    </ListItem>)}
                  </List>
                </Box>}>
                <Table id={0}/>
              </Suspense>
            </Grid>
          </Hidden>
          <Grid item xs>
            <Suspense fallback={<Loader/>}>
              <Switch>
                <Route path="/about"><About /></Route>
                <Route path="/dcma"><Dcma /></Route>
                <Route path="/contact"><Contact /></Route>
                <Route path="/privacy"><Privacy /></Route>
                <Route path="/game/:uid/:id"  children={<Game />}/>
              </Switch>
            </Suspense>
            <Suspense fallback={<OutlinedCardSkeleton/>}>
              <Switch>
                <Route exact path="/"><Home /></Route>
              </Switch>
            </Suspense>
          </Grid>

          <Hidden only={['xs', 'sm']}>
            <Grid item md={3}>
              <AdUI format="horizontal"/>
              <AdUI format="rectangle"/>
              <Suspense fallback={
                <Box my={4} style={{position: 'sticky',top: '0'}}>
                  <List style={{paddingTop:'0'}}>
                  {[0,1,2,3,4].map(x =>
                    <ListItem key={x}>
                      <ListItemIcon><Skeleton animation="wave" variant="circle" width={24} height={24}/></ListItemIcon>
                      <ListItemText><Skeleton animation="wave" height={10} width="70%" /></ListItemText>
                    </ListItem>)}
                  </List>
                </Box>}>
                <Table id={1}/>
              </Suspense>
            </Grid>
          </Hidden>

        </Grid>
        <AdUI format="auto"/>
      </Container>
      <Divider/>
      <Footer/>
    </>
  );
}
App.propTypes = {
  fetchGames: PropTypes.func.isRequired,
  fetchTable: PropTypes.func.isRequired,
  games: PropTypes.array.isRequired,
  gamesLoaded: PropTypes.bool.isRequired,
  tableLoaded:PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  games: state.games.items,
  tableLoaded:state.games.tableLoaded,
  gamesLoaded: state.games.itemsLoaded
});
export default connect(mapStateToProps, { fetchGames,fetchTable })(App);
