import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import uuid from "../../utils/uniqye";
import Box from "@material-ui/core/Box";

const AdUI = ({format,withId}) => {
    /*let location = useLocation();
    const [id,setId] = useState(location.key+uuid())
    useEffect(() => {
        setId(location.key+uuid())
    }, [location.key]);
    useEffect(() => {
        try {
            window.adsbygoogle.push({})
        } catch (e) {
            console.log(e)
        }
    }, [id]);*/
    return (<></>)
    {/*
        <Box id={id} my={2}>
            <React.Fragment key={id}>
                <ins className={'adsbygoogle'}
                     style={{display: 'block', textAlign: 'center'}}
                     data-ad-client="ca-pub-2743158491365756"
                     data-ad-slot={withId ? "9728306896" : "4834083181"}
                     data-full-width-responsive="true"
                     data-ad-format={format ?? "auto"}
                />
            </React.Fragment>
        </Box>
    */}
}
export default AdUI