import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Linkw from '@material-ui/core/Link';
import {AppBar, Button, Tab, Tabs, Toolbar, Typography} from '@material-ui/core';
import {ContactMailRounded, Home, Info, LockRounded, PolicyRounded} from '@material-ui/icons';
import {Link, useLocation} from "react-router-dom";
import {green} from '@material-ui/core/colors';
import AdUI from "./ad/AdUI";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .Mui-selected':{
      color: green[500]
    },
    '& .MuiTab-root':{
      minWidth: '85px'
    },
    '& .MuiTabs-indicator':{
      backgroundColor: green[500],
      height: '5px',
      borderRadius: '20px 20px 0 0'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  game:{
    display:'none'
  }
}));

export default function MainAppBar(props) {
  const { to } = props;
  let l = useLocation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  useEffect(() => { setValue(['/','/about','/contact','/dcma','/privacy'].indexOf(l.pathname))} , [setValue,l]);
  const CustomLink = React.forwardRef((props, ref) => ( <Link to={to} ref={ref} {...props} />));
  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Typography variant="h5" component="h1" align="center" className={classes.title}>MLB Streams</Typography>
        <Tabs indicatorColor="primary" textColor="primary" centered value={value} className={classes.root}>
           <Tab label="Home" component={CustomLink} to="/" icon={<Home />}/>
           <Tab label="About" component={CustomLink} to="/about"icon={<Info />}/>
           <Tab label="Contact" component={CustomLink} to="/contact" icon={<ContactMailRounded />} />
           <Tab label="Dcma" component={CustomLink} to="/dcma" icon={<LockRounded/>}/>
           <Tab label="Privacy" component={CustomLink} to="/privacy" icon={<PolicyRounded />} />
           <Tab className={classes.game} value={-1} />
         </Tabs>
         <Button color="inherit">
           <Linkw href="https://discord.gg/gyEZW4zRz6" target="_blank" rel="noopener" underline="none" color="inherit">
             Join Discord
           </Linkw>
         </Button>
       </Toolbar>
        <div align={"center"} ><AdUI style={{maxHeight: "300px",maxWidth: "728px"}} format="horizontal"/></div>
    </AppBar>
  );
}
