import {
    DESTROY_GAME,
    FETCH_GAME,
    FETCH_GAME_DATA,
    FETCH_GAME_DATA_ERROR,
    FETCH_GAMES,
    FETCH_STREAM,
    FETCH_TABLE
} from './types';

export const fetchGames = () => dispatch => {
  fetch('https://sky.streamsgun.com/api/v1/fun/baseball/mlb/games') //https://blazer.nbastreams.fun/api/games
    .then(res => res.json())
    .then(games =>
      dispatch({
        type: FETCH_GAMES,
        payload: games
      })
    );
};
export const fetchTable = () => dispatch => {
  fetch('https://sky.streamsgun.com/api/v1/mlbfun/table')
    .then(res => res.json()).then(games =>
      dispatch({
        type: FETCH_TABLE,
        payload: games
      })
    );
};
export const fetchGameData = (id) => dispatch => {
  fetch('https://sky.streamsgun.com/api/v1/mlb/event/'+id) //https://blazer.nbastreams.fun/api/games
    .then(res => res.json()).then(games =>
      dispatch({
        type: FETCH_GAME_DATA,
        payload: games
      })
    ).catch(e => dispatch({type: FETCH_GAME_DATA_ERROR}));
};
export const fetchGame = (id) => dispatch => {
  dispatch({
    type: FETCH_GAME,
    payload: id
  })
};
export const destroyGame = () => dispatch => {
  dispatch({
    type: DESTROY_GAME
  })
};
export const fetchStream = (id) => dispatch => {
  fetch('https://sky.streamsgun.com/api/v1/mlbfun/'+id+'/streams')
    .then(res => res.json())
    .then(stream =>
      dispatch({
        type: FETCH_STREAM,
        payload: stream,
        meta: id
      })
    );
};
