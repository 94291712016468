import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    body1 : {
      fontSize: '0.9rem',
    },
   fontFamily: [
     '-apple-system',
     'BlinkMacSystemFont',
     '"Segoe UI"',
     'Ubuntu',
     '"Helvetica Neue"',
     'Arial',
     'sans-serif',
     '"Apple Color Emoji"',
     '"Segoe UI Emoji"',
     '"Segoe UI Symbol"',
   ].join(','),
 },
});
export default theme;
